@font-face {
	font-family: "phenomena";
	src: url(../fonts/phenomena/Phenomena-Regular.ttf);
}
h2{
		font-family: "phenomena";
		font-size: 48px;
	}  
	p{
	font-size: 14px;

	line-height: 25px;
}
	.cursor-pointer{
		cursor: pointer;
	}
	.text-ecarlate{
		color: #900005;
	}
.text-orange{
	color: #ff9b2a;
}
.tooltip-inner {
	max-width: 500px;
	width: 500px;
}
.f15rem{
	font-size:1.5rem;
}
.question-color{
	color: #34a0c0;
}

	/*COLOR*/
	.bleu-ingenierisk{
		background-color:#34a0c0;
	}
	.vert-ingenierisk{
		background-color: #6dba78;
	}
	.text-bleu-ingenierisk{
		color: #34a0c0 !important;
	}
	a.text-bleu-ingenierisk:hover{
		color: #6dba78 !important;
	}
	.text-vert-ingenierisk{
		color: #6dba78 !important;
	}
	a.text-vert-ingenierisk:hover{
		color: #34a0c0 !important;
	}
	.btn-vert-ingenierisk{
		background-color: #6dba78;
		border: 1px solid #6dba78;
		color:white;
	}
	.btn-vert-ingenierisk:hover{
		background-color: white;
		border: 1px solid #6dba78;
		color: #6dba78;
	}
	.btn-bleu-ingenierisk{
		background-color: #34a0c0;
		border: 1px solid #34a0c0;
		color:white;
	}
	.btn-bleu-ingenierisk:hover{
		background-color: white;
		border: 1px solid #34a0c0;
		color: #34a0c0;
	}
	.vert-couleur, .icon-background-green{
		color:#a2c617;
	}
	.orange-couleur, .icon-background-orange{
		color: #e4a232;
	}
	.gris-couleur, .icon-background-gris{
		color:#a7a9ac;
	}
	.icon-background-green-inge{
		color:#6dba78;
	}
	.rouge{
		background-color: red;
	}
	.vert{
		background-color: #a2c617;
	}
	.orange{
		background-color: #e4a232;
	}

	/* TEXTE */
	.font-weight-semibold{
		font-weight: 600;
	}
	.bold{
		font-weight: bold;
	}
	.light-bold{
		font-weight: 400;
	}
	.title-article{
		font-size: x-large;
	}
	.article-loi{
	font-size: smaller;
	}
	.underline{
		text-decoration: underline;
	}

	.uppercase{
		text-transform: uppercase;
	}
	.contact-info{
		font-size: 18px;
	}
	#searchLoi{
		cursor: pointer;
	}

	/* MAIN */
	body {
		background-color: white;
	}
	.container{
		max-width: 86%;
	}
	hr {
		-moz-border-bottom-colors: none;
		-moz-border-image: none;
		-moz-border-left-colors: none;
		-moz-border-right-colors: none;
		-moz-border-top-colors: none;
		border-color: #EEEEEE -moz-use-text-color #FFFFFF;
		border-style: solid none;
		border-width: 1px 0;
		margin: 18px 0;
		color: #6dba78;
		border-top: 0px solid #6dba78 !important;
	}
	.full-width-separator{
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.text-separator{
		padding: 15px 25px;
		border: 1px solid white;	
	}
	.list-no-style{
		list-style: none;
	}
	strong{
		color: #6dba78 !important;
		font-weight: normal;
	}
	i{
		color:#34a0c0;
	}
	.form-control{
		border-radius: 0px;
	}

	/* HEADER */
	.second-header{
		border-bottom: 1px solid #A7A9AC;
	}
	.main-menu li a{
		font-weight: 600;
	}
	.header-logo{
		border-bottom: 2px solid #A7A9AC;
	}
	.slogan{
		font-size: 18px;
		font-weight: 600;
	}
	.sticky-top-main{
		position: sticky;
		top: 109;
		z-index: 1020;
	}
	.dropdown-divider {
		height: 0;
		margin: .5rem 0;
		overflow: hidden;
		border-top: 1px solid #34a0c0;
	}
	.dropdown-menu {
		border-radius: 0;
	}

	/* FOOTER */
	footer{
		background-color: #eee;
		padding-top: 20px;
		margin-top: 100px;
	}
	.footer-title{
		font-weight: 700;
	}
	.hr-footer{
		-moz-border-bottom-colors: none;
		-moz-border-image: none;
		-moz-border-left-colors: none;
		-moz-border-right-colors: none;
		-moz-border-top-colors: none;
		border-color: #EEEEEE -moz-use-text-color #FFFFFF;
		border-style: solid none;
		border-width: 4px 0;
		margin: 10px 0 15px 0;
		color: black;
		width: 50px;

	}

	/* SLIDER */
	.carousel-item {
		height: calc(100vh - 221px);
		min-height: 350px;
		background: no-repeat center center scroll;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}
	.carousel-item .layer {
		background-color: rgba(0, 0, 0, 0.7);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.carousel-caption{
		bottom: 40% !important;
	}

	/* IMAGE */
	.image-100{
		width: 100%;
		height: 90%;
	}


	/* ACCUEIL */
	.tab-accueil-1 .tab-pane{
		background-color: #eeeeee;
	}
	.tab-accueil-1 .nav-tabs{
		border-top:1px solid #6dba78;
		border-left:1px solid #6dba78;
		border-right:1px solid #6dba78;
	}
	.tab-accueil-1 .nav-item{
		border-radius: 0;
		border: 0;

	}
	.tab-accueil-1 .nav-item.active{
		background-color: #eeeeee !important;
		border-top: 1px solid #6dba78;
		font-weight: bold;
		color: black;
	}
	.tab-accueil-1 .tab-content{
		padding: 10px;
		background-color: #eeeeee !important;
		border-left: 1px solid #6dba78;
		border-right: 1px solid #6dba78;
		border-bottom: 1px solid #6dba78;
	}
	.tab-accueil-2 .nav-item{
		border-radius: 0;
		border: 1px solid #A7A9AC;

	}
	.tab-accueil-2 .nav-item.active{
		background-color: #34a0c0 !important;
		border: 1px solid #34a0c0;
		font-weight: bold;
		color: white;
	}
	.tab-accueil-2 .nav-item.active i{
		color: white;
	}
	.tab-accueil-2 .tab-content{
		padding: 10px;
	}


	.fa-stack-1x{
		color: white;
	}
	.my-card
	{
		position: relative;
		/* left: 30%; */
		/* top: -60px; */
		width: 100%;
		text-align: center;
	}
	.p-3 {
		height: 100%;
	}
	.carte-theme, .carte-referentiel{
		padding-left: 70px;
		padding-right: 70px;
		margin-top: 60px;
		margin-bottom: 40px;
	}
	.carte-theme p, .carte-referentiel p{
		margin-bottom: 2rem;
		font-size: 14px;
		text-align: left;
		color: grey;
	}
	.carte-referentiel .card{
		padding: 50px 0px 20px 0px !important;
	}
	.logo-theme{
		position: absolute;
		width: 100%;
		top: -61px;
		left: 0;
	}
	.carte-theme-titre{
		margin-top: 25px;
	}
	.carte-referentiel .btn{
		width: 45%;
	}
	.absolute-button-container{
		position: absolute;
		width: 100%;
		bottom: -25px;
		left: 0;
	}
	.table-inside tr{
		background-color: rgba(0, 0, 0, 0) !important;
	}
	.table-inside td{
		border: 0 !important;
	}
	.table{
		margin-bottom: 0 !important;
	}

	.carte-loi{
		background-color: #e74c3d;
		padding-top:15px;
		padding-bottom:15px;
		padding-left: 0;
		padding-right: 0;
	}
	.logo-loi{
		text-align: center;
		font-size: 50px;

	}
	.logo-loi i{
		color: white;
	}
	.containing-table {
		display: table;
		width: 100%;
		height: 150px;
	}
	.centre-align {
		padding: 10px;
		display: table-cell;
		text-align: center;
		vertical-align: middle;
	}
	.loi-separator{
		width: 25%;
		text-align: center;
		margin: 0 auto;
		color: white;
		border-width: 4px 0;
	}
	.loi-source{
		width: 100%;
		text-align: center;
		background-color: white;
		margin-top: 10px;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.loi-title{
		font-size: 28px;
		font-weight: 700;
	}
	.loi-container{
		margin-top:25px;
		margin-bottom: 50px;
	}
	.action-table td{
		height: 100px;
	}
	.modal-action, .modal-fonction{
		width: 90%;
		max-width: none !important;
	}
	#selectSociete, #selectPays{
		background-color: #34a0c0;
		color:white;
		border: none;
		padding:5px;
		margin-top: 12px;
		width: 150px;
	}
	.first-container{
		padding-top: 30px;
		margin-bottom: 35px;
	}
	.border-theme{
		border-color: grey;
	}
	.first-title{
		text-decoration : none; 
		padding : 0 0 5px 0; 
		border-bottom : 2px solid #6dba78; 
		display: inline-block;
	}
	span.legend-referentiel {
		margin-right: 20px;
		font-size: 14px;
	}
	span.legend-referentiel i {
		font-size: 22px;
	}
	.legend-referentiel-container{
		margin-left:20px;
	}
	.question-table td, .question-table tr, .question-table th{
		border: 0;
	}
	.btn-etape{
		width: 200px;
		float: right;
		margin-top: 25px;
	}
	.btn-valide-action{
		width: 200px;
		margin-top: 25px !important;
		margin: 0 auto;
		display: block
	}
	.evaluation-table th, .evaluation-table-result th{
		text-align: center;
		color:white;
		background-color: #34a0c0;
		vertical-align: middle;
		border-top: 1px solid #34a0c0;
	}
	.evaluation-table tbody tr td, .evaluation-table th{
		border-left: 3px solid #a7a9ac !important;
	}
	.evaluation-table tbody tr td:first-child, .evaluation-table thead tr th:first-child{
		border-left: none !important;
	}
	.evaluation-table tbody tr td, .evaluation-table-result tbody tr td{
		border-top: 0;
		border-bottom: 0;
	}
	.select-risque{
		text-align: center;
	}
	.select-risque select{
		background-color: #34a0c0;
		color:white;
		border: none;
		padding:5px;
		width: 90%;
	}
	.detection-table{
		text-align: center;
		font-weight: 500;
	}
	.eval-form{
		width: 100%;
	}
	.eval-form table th{
		text-align: center;
	}
	.question-reponse-choice{
		text-align: center;
		vertical-align: middle !important;
	}
	.custom-file label::after{
		display: none;
	}
	.custom-file{
		margin-top: 15px;
	}
	.btn-on-td{
		width: 100%;
		margin-top: 10px;
	}
	.input-charge-calcul{
		width: 90%;
		background-color: #ffffff00;
		border: 1px solid #34a0c0;
		padding: 0 10px;
	}
	.currency-symbol{
		font-size: 26px;
		font-weight: 600;
		margin-left: 10px;
	}
	h3.table-description {
		margin-top: 50px;
		margin-bottom: 20px;
	}
	.center-content{
		text-align: center;
	}
	.button-container{
		width: 100%;
		margin-top: 25px;
	}
	.gestion-pilote{
		width:35%;	
		margin-right: 2px;
		margin-bottom: 5px;
	}
	.gestion-email-pilote{
		width: 60%;
	}
	.gestion-date{
		width: 70%;
	}
	.gestion-date-label{
		font-weight: bold;
	}
	.btn-action{
		width: 90% !important;
		margin-bottom: 10px;
	}
	.btn-action-edit{
		width: 90%;
	}
	.carte-referentiel-fonction .card{
		padding: 10px;
	}
	.carte-referentiel-fonction{	
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.archive-logo{
		font-size: 48px;
	}
	.box-expert {
		border: 1px solid #34a0c0;
		margin-bottom: 50px;
		text-align: center;
		padding:0;
	}
	.photo-expert {
		display: inline-block;
		margin-bottom: 0 !important;
		width: 100%;
		height: 200px;
		overflow: hidden;
	}
	.photo-expert img {
		width: 100%;
	}
	.nom-expert {
		display: inline-block;
		width: 100%;
		background-color: #34a0c0;
		padding: 5px 0;
		margin: 15px 0;
		color: white;
	}
	.description-expert-fonction, .description-expert {
		padding: 5px;
		display: inline-block;
		text-align: left;
	}
	.coordonnees-expert {
		display: inline-block;
		width: 90%;
		border-top: 1px solid #52b475;
		padding-top: 20px;
		text-align: left;
	}
	.coordonnees-expert p {
		margin-bottom: 5px;
		text-align: left;
	}
	.sidebar {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 100;
		padding: 48px 0 0;
		box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
	}
	@media (min-width: 768px){
		[role="main"] {
			padding-top: 48px;
		}
	}
	#questionContainer{	

	}
	#questionContainer input{
		margin-bottom: 10px;
	}
	#questionContainer label{
		float: left;
		margin-right: 25px;
		font-size: 20px;
	}

	.containerQuestion{
		padding: 5px;
		border: 1px solid grey;
	}	
	.vulnerabiliteContainer{
		background-color: #ececec;
		padding: 25px;
		margin-bottom: 25px;
	}
	.causeContainer{
		background-color: #dcdcdc;
		padding: 25px;
		margin-bottom: 25px;
	}
	.recommandationContainer{
		background-color: #cccccc;
		padding: 25px;
		margin-bottom: 25px;
	}
	.risqueContainer{
		background-color: #bcbcbc;
		padding: 25px;
		margin-bottom: 25px;
	}
	.containerRisque{
		margin-bottom: 25px;
	}
	.logo-sujet:before{
		border: 1px solid #34a0c0;
		border-radius: 50%;
	}
	.logo-sujet{
		color:white;
	}
	.risquePassage, .conduiteTenir, .profil, .statut{
		padding: 10px 5px;
	}

	.user-radic-1{
		color: #385623;
	}
	.user-radic-2, .profil-icon-1{
		color: #ffd965;
	}
	.resultat-sujet{
		position: fixed;
		right: 50px;
		top:300px;
		z-index: 99;
	}
	.questionnaireTitle{
		margin-top: 3rem;
	}
	.diagnosticContainer{
		padding: 20px 0;
	}
	.button-archive-sujet{
		margin-top:20px;
	}

	.risquePassage.degre-1{
		background-color: green;
		color:white;
	}
	.risquePassage.degre-2{
		background-color: yellow;	
		color:black;
	}
	.risquePassage.degre-3{
		background-color: orange;	
		color:white;
	}
	.risquePassage.degre-4{
		background-color: red;
		color:white;
	}

	.degre-profil-1, .degre-etat-1{
		background-color: #385623;
		color:white;
	}
	.degre-profil-2{
		background-color: #548135;	
		color:white;
	}
	.degre-profil-3{
		background-color: #a8d08d;	
		color:black;
	}
	.degre-profil-4{
		background-color: #ffd965;
		color:black;
	}
	.degre-profil-5, .degre-etat-2{
		background-color: #e2a700;
		color:black;
	}
	.degre-profil-6{
		background-color: #bf9000;	
		color:white;
	}
	.degre-profil-7{
		background-color: #ff552d;	
		color:white;
	}
	.degre-profil-8{
		background-color: #ff3300;
		color:white;
	}
	.degre-profil-9{
		background-color: #ff0000;
		color:white;
	}
	.degre-profil-10{
		background-color: #cc0066;	
		color:white;
	}
	.degre-profil-11{
		background-color: #990033;	
		color:white;
	}
	.degre-profil-12{
		background-color: #cc0000;
		color:white;
	}

	.warning-1{
		color: #385623;
		font-size: 32px;
	}
	.warning-2{
		color: #548135;
		font-size: 32px;	
	}
	.warning-3{
		color: #a8d08d;
		font-size: 32px;	
	}
	.warning-4{
		color: #ffd965;
		font-size: 32px;
	}
	.warning-5{
		color: #e2a700;
		font-size: 32px;
	}
	.warning-6{
		color: #bf9000;	
		font-size: 32px;
	}
	.warning-7{
		color: #ff552d;
		font-size: 32px;	
	}
	.warning-8{
		color: #ff3300;
		font-size: 32px;
	}
	.warning-9{
		color: #ff0000;
		font-size: 32px;
	}
	.warning-10{
		color: #cc0066;
		font-size: 32px;	
	}
	.warning-11{
		color: #990033;	
		font-size: 32px;
	}
	.warning-12{
		color: #cc0000;
		font-size: 32px;
	}
	.question-libelle{
		padding-left:0 !important;
		background-color:#cccccc;
		font-size: 14px;
	}
	.question-tooltip{
		background-color: #e6e6e6;
		font-size: 10px !important;
		margin-top: 0;
	}
	.submit-radic{
		width: 250px;
		margin: 0 auto;
		margin-top: 25px !important;
	}
	.submit-container{
		text-align: center;
	}
	.radic-file{
		width: auto;
		text-align: center;
		margin-top: 5px;
	}
	.radic-table th{
		text-align: center;
	}
	.question-reponse-choice .custom-radio{
		margin: 0 !important;
	}
	#questionSupBody .custom-file-label, #questionRadicBody .custom-file-label{
		font-size: 10px;
	}
	.glossaire-theme-card{
		margin-bottom: 50px;
	}
	.definition-container{
		
	}
	.definition-solo-container{
		background-color: #f3f3f3;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 50px;
	}
	.title-definition{
		padding-left: 0;
	}

	.card-container{
	padding: 20px;
	background: linear-gradient(to right, #f5f5f5 0%,#f5f5f5 50%,#f5f5f5 65%, #ffffff 65%, #ffffff 100%);
	margin-top: 50px;
}
.card-text{

}
.card-image img{
	width: 100%;
	box-shadow: 0px 0px 15px 3px #505050;
	border-radius: 5px;
}
.card-image-without-shadow img{
	width: 100%;
}
.row-card-white{
	border-radius: 5px;
	background-color: white;
	box-shadow: 0px 0px 15px 3px #505050;
	padding: 25px 0;
}
.container-margin{
	margin-top: 75px;
}
.margin-50{
	margin-top:25px;
}
.bottom-25{
	margin-bottom: 25px;
}
.top-50{
	margin-top: 50px;
}
.no-border{
	border: 0 !important;
}
td{
	vertical-align: middle !important;
}
.formulaire-contact{
	border: 1px solid #34a0c0;
	padding: 40px 20px;
	background: #f3f3f3;
}
.contact-social{
	color: white;
	background-color: #34a0c0;
	border-radius: 5px;
	margin-right: 40px;
	line-height: 40px;
	font-size: 24px;
	padding: 5px;
	width: 50px;
	height: 50px;
	text-align: center;
	vertical-align: middle;
}

.font-42{
	font-size: 42px;
}
.dropdown-menu{
	width: 100%;
	text-align: center;
}
.title-tooltip{
	font-size:24px;
}
